import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { message } from "antd";

export const getUserProjects = createAsyncThunk(
  "project/get-user-projects",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      const res = await api.get(
        `timecard/project/?page=${page}&page_size=${pageSize}`
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getProjectDetails = createAsyncThunk(
  "project/details",
  async (slug, thunkAPI) => {
    try {
      const res = await api.get(`timecard/project/${slug}/`);
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createNewProject = createAsyncThunk(
  "project/create",
  async (data, thunkAPI) => {
    try {
      const res = await api.post("timecard/project/", data);
      if (res.status === 201) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateProject = createAsyncThunk(
  "project/update",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await api.put(`timecard/project/${id}/`, data);
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// ToDo: ADD Delete or Inactive Project Actions

// Project Categories
export const getProjectCategories = createAsyncThunk(
  "project/all-categories",
  async ({ id, qparams }, thunkAPI) => {
    try {
      const res = await api.get(
        `timecard/project-category/${id}/${qparams ? qparams : ""}`
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addProjectCategories = createAsyncThunk(
  "project/add-category",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await api.post(`timecard/project-category/${id}/`, data);
      if (res.status === 201) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateProjectCategory = createAsyncThunk(
  "project/update-category",
  async ({ projectID, id, data }, thunkAPI) => {
    try {
      const res = await api.put(
        `timecard/project-category/${projectID}/${id}/`,
        data
      );
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteProjectCategory = createAsyncThunk(
  "project/delete-category",
  async ({ projectID, id }, thunkAPI) => {
    try {
      const res = await api.delete(
        `timecard/project-category/${projectID}/${id}/`
      );
      if (res.status === 204) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// Project Members
export const getProjectMembers = createAsyncThunk(
  "project/get-members",
  async ({ id, queries }, thunkAPI) => {
    try {
      let qparams = "";
      if (
        Object.hasOwn(queries, "categories") &&
        queries.categories.length > 0
      ) {
        qparams += `category__in=${queries.categories.join(",")}&`;
      }
      if (Object.hasOwn(queries, "locations") && queries.locations.length > 0) {
        qparams += `location__in=${queries.locations.join(",")}&`;
      }

      if (Object.hasOwn(queries, "active")) {
        qparams += `active=${queries.active}&`;
      }

      const res = await api.get(`timecard/project-members/${id}/?${qparams}`);

      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getProjectMemberDetails = createAsyncThunk(
  "project/get-project-member-details",
  async ({ projectID, memberID }, thunkAPI) => {
    try {
      const res = await api.get(`timecard/project-members/${projectID}/${memberID}/`);
      if (res.status === 200) {
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addProjectMember = createAsyncThunk(
  "project/add-member",
  async ({ id, data }, thunkAPI) => {
    try {
      message.loading({
        key: "add-member",
        content: "Adding Project Member",
      });
      const res = await api.post(`timecard/project-members/${id}/`, data);
      if (res.status === 201) {
        message.success({
          key: "add-member",
          content: "Member Added Successfully!",
        });
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      message.error({
        key: "add-member",
        content: "Member Add Failed!",
      });
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateProjectMember = createAsyncThunk(
  "project/update-member",
  async ({ projectID, id, data }, thunkAPI) => {
    try {
      message.loading({
        key: "update-member",
        content: "Updating Member....",
      });
      const res = await api.put(
        `timecard/project-members/${projectID}/${id}/`,
        data
      );
      if (res.status === 200) {
        message.success({
          key: "update-member",
          content: "Member Updated!",
        });
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      message.error({
        key: "update-member",
        content: "Member Update Failed!",
      });
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteProjectMember = createAsyncThunk(
  "project/delete-member",
  async ({ projectID, id }, thunkAPI) => {
    try {
      message.loading({
        key: "delete-member",
        content: "Deleting Member....",
      });
      const res = await api.delete(
        `timecard/project-members/${projectID}/${id}/`
      );
      if (res.status === 204) {
        message.success({
          key: "delete-member",
          content: "Member Deleted!",
        });
        return res.data;
      } else {
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      message.error({
        key: "delete-member",
        content: "Member Deletion Failed!",
      });
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const joinProjectRequest = createAsyncThunk(
  "project/approve-project-request",
  async ({ projectID, email }, thunkAPI) => {
    try {
      message.loading({
        key: "join-project",
        content: "Joining project..",
      });
      const res = await api.post(`timecard/join-project/${projectID}/`, {
        email,
      });
      // Handle success
      if (res.status === 200) {
        message.success({
          key: "join-project",
          content: res.data.message || "Project joined successfully.",
        });
        return res.data;
      } else {
        // Handle non-200 but valid responses
        return thunkAPI.rejectWithValue(res.data);
      }
    } catch (err) {
      // Handle errors
      const errorMessage =
        err.response?.data?.error || "Failed to approve project join request.";
      message.error({
        key: "join-project",
        content: errorMessage,
      });
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

const initialState = {
  loadingUserProjects: false,
  error: null,
  userProjects: [],
  loadingProject: false,
  projectDetails: null,
  loading: false,
  projectCategories: [],
  category: null,
  projectMembers: [],
  // memberTimeCard: [],
};

const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    resetUserProjects: (state) => {
      state.loadingUserProjects = false;
      state.userProjects = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProjects.pending, (state) => {
        state.loadingUserProjects = true;
      })
      .addCase(getUserProjects.fulfilled, (state, action) => {
        state.loadingUserProjects = false;
        state.userProjects = action.payload;
      })
      .addCase(getUserProjects.rejected, (state) => {
        state.loadingUserProjects = false;
        state.userProjects = [];
      })
      .addCase(getProjectDetails.pending, (state) => {
        state.loadingUserProjects = true;
      })
      .addCase(getProjectDetails.fulfilled, (state, action) => {
        state.loadingUserProjects = false;
        state.projectDetails = action.payload;
      })
      .addCase(getProjectDetails.rejected, (state) => {
        state.loadingUserProjects = false;
        state.projectDetails = null;
      })
      .addCase(createNewProject.pending, (state) => {
        state.loadingProject = true;
      })
      .addCase(createNewProject.fulfilled, (state, action) => {
        state.loadingProject = false;
        state.projectDetails = action.payload;
      })
      .addCase(createNewProject.rejected, (state) => {
        state.loadingProject = false;
        state.projectDetails = null;
      })
      .addCase(updateProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projectDetails = action.payload;
      })
      .addCase(updateProject.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProjectCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.projectCategories = action.payload;
      })
      .addCase(getProjectCategories.rejected, (state) => {
        state.loading = false;
        state.projectCategories = [];
      })
      .addCase(addProjectCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(addProjectCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.category = action.payload;
      })
      .addCase(addProjectCategories.rejected, (state) => {
        state.loading = false;
        state.category = null;
      })
      .addCase(getProjectMembers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.projectMembers = action.payload;
      })
      .addCase(getProjectMembers.rejected, (state) => {
        state.loading = false;
        state.projectMembers = [];
      });
  },
});

export default projectSlice;
